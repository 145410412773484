import React, { Component } from 'react';
import { Link } from "react-router-dom";
import Table from "../common/table";
import InputCheckboxes from '../common/inputCheckboxes';

class MyProductsTable extends Component {
    render() { 
        const {products, sortColumn, onSort, onSelect, selectedProducts} = this.props;
        const tableColumns = [
            {
                name: '',
                key: 'select-link',
                path: "select-link",
                content: product => <InputCheckboxes
                    name={"prod-select-"+product.uuid}
                    classes=''
                    label={''} 
                    options={[{_id:product.uuid, name:product.name}]}
                    onChange={onSelect} 
                    error={''}
                    helpId={''} 
                    helpText={''}
                    value={selectedProducts}
                />
            },
            {
                name: '',
                key:'images',
                path: "images",
                content: product => this.renderProductImages(product)
            },
            {
                name: '', 
                key:'edit-link',
                path: "edit-link",                
                content: product => <Link title="edit product" to={`/myproduct/edit/${product.uuid}`}>{product.productName}</Link>
            },
            // {name: 'Store', path: 'store.storeName'},
            {name: 'Price', path: 'price', format:'currency', class: "product-price"},
            {name: 'Published?', 
                key: 'published',
                content: product => product.published && product.published === true ? "yes" : "no"
            },
            {name: 'In Stock?', 
                key: 'in-stock',
                content: product => product.quantityAvailable && product.quantityAvailable > 0 ? "yes" : "no"
            }
        ];
        return (
            <Table 
                tableClasses="pb-table products-table"
                data={products}
                tableColumns={tableColumns}
                sortColumn={sortColumn}
                onSort={onSort}
            />
        );    
    }

    renderProductImages(product) {
        if(product.media && product.media[0]) {
            return <div className="grid-image"><img src={product.media[0].url} alt={product.media[0].title} /></div>;
        }
         return '';    
    }
}

export default MyProductsTable;