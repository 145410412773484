import React, { Component } from 'react';
import Table from "../common/table";
import Pagination from '../common/pagination';
import SearchBox from '../common/searchBox';
import { toast } from 'react-toastify';
import * as adminService from '../../services/adminService';
import {formatTimestamp} from "../../utils/format.js"

class DashboardActivityTable extends Component {
    
    state = {  
        activityData: [],
        searchValue:'',
        limit: 10,
        sortColumn: {path:"created", order:"desc"},
        init: false
    } 

    async componentDidMount() {
        if(this.state.init) {
            return;
        }
        const activityData = await this.getData(0, this.state.limit, this.state.sortColumn.path, this.state.sortColumn.order, '');
        this.setState({activityData,init:true});
    }

    getData = async(start, limit, sortColumn, sortOrder, searchValue) => {
        let activityData = [];
        try {
            let response = await adminService.getDashboardData('activity',searchValue,start,limit,sortColumn,sortOrder);
            if(response && response.data) {
                activityData = response.data;
            }
        } catch(exception) {
            if(exception.response && exception.response.data) {
                const msg = exception.response.data.message ? exception.response.data.message : "an unhandled exception occurred";
                toast.error(msg);
            } else {
                toast.error("an unhandled exception occurred");
            }
        }
        return activityData;
    }

    handleFilterByPage = async (page) => {    
        const pageInfo = this.state.activityData.pageInfo;
        pageInfo.currentPage = page;
        const start = pageInfo.pageSize * (page-1);
        
        const activityData = await this.getData(start, pageInfo.pageSize, this.state.sortColumn.path, this.state.sortColumn.order, this.state.searchValue);
        this.setState({activityData});
    }
    
    handleSortByColumn = async sortColumn => { 
        const pageInfo = this.state.activityData.pageInfo;
        const activityData = await this.getData(pageInfo.start, pageInfo.pageSize, sortColumn.path, sortColumn.order, this.state.searchValue);
        this.setState({activityData,sortColumn});
    }
 
    handleSearch = async searchValue => {
        const pageInfo = this.state.activityData.pageInfo;
        const activityData = await this.getData(pageInfo.start, pageInfo.pageSize, this.state.sortColumn.path, this.state.sortColumn.order, this.state.searchValue);
        this.setState({searchValue, activityData});
    }

    render() { 
        const {activityData, searchValue, sortColumn} = this.state;
        const tableColumns = [
            {
                name: 'Severity', 
                path: "logSeverity",
                key: 'severity'
            },
            {
                name: 'Message', 
                path: 'logMessage',
                key: 'message'
            },
            {
                name: 'Resource', 
                path: 'resource',
                key: 'resource'
            },
            {
                name: 'Action', 
                path: 'action',
                key: 'action'
            },
            {
                name: 'IP Address', 
                path: 'ipAddress',
                key: 'ipAddress'
            },
            {
                name: 'Created',
                path: 'created',
                key: 'created',
                content: activity => formatTimestamp(activity.created, "sortabletimestamp")
            }
        ];

        if(activityData.pageInfo) {
            //if(merchantData && merchantData.pageInfo && null !== merchantData.documents) {
            return (
                <React.Fragment>
                    <Pagination 
                    currentPage={activityData.pageInfo.currentPage}
                    itemsCount={activityData.pageInfo.rowCount} 
                    pageSize={activityData.pageInfo.pageSize}
                    onPageChange={this.handleFilterByPage} />    
                    <SearchBox value={searchValue} onChange={this.handleSearch} />  
                    <Table 
                        data={activityData.documents}
                        classField="logSeverity"
                        tableColumns={tableColumns}
                        sortColumn={sortColumn}
                        onSort={this.handleSortByColumn}
                    />
                </React.Fragment>
            );    
        } else {
            return (
                <React.Fragment>
                    <SearchBox value={searchValue} onChange={this.handleSearch} />
                    <p>No matching records.</p>
                </React.Fragment>
            );       
        }

    }
}

export default DashboardActivityTable;