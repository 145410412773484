import React, { Component } from 'react';
// import { Link } from "react-router-dom";
import Table from "../common/table";
import InputCheckboxes from '../common/inputCheckboxes';

class EntityImagesTable extends Component {
    render() { 
        const {images, sortColumn, onSort, onSelect, selectedImages} = this.props;
        // onClickEdit, 
        const tableColumns = [
            {
                name: '',
                key: 'select',
                path: "select-link",
                content: image => <InputCheckboxes
                    name={"option-select-"+image._id}
                    classes=''
                    label={''} 
                    options={[{_id:image._id, name:''}]}
                    onChange={(evt) => onSelect(evt) }
                    error={''}
                    helpId={''} 
                    helpText={''}
                    value={selectedImages}
                />
            },
            {
                name: '',
                key: 'image-thumb',
                content: image => <img src={image.data} alt={image.image.alt} />
            },
            {
                name: '', 
                key: 'image-name',
                path: "image.name",                
            },
            // {
            //     name: '', 
            //     key: 'edit',
            //     path: "edit-link",                
            //     content: image =>
            //         <React.Fragment><button className="btn btn-success btn-sm" onClick={(evt) => onClickEdit(evt,image)}>Edit</button>
            //         {image.image.name}</React.Fragment>
            // },
            {name: 'Alternative Text', path: 'image.alt'},
        ];
        return (
            <Table 
                tableClasses="pb-table images-table"
                data={images}
                tableColumns={tableColumns}
                sortColumn={sortColumn}
                onSort={onSort}
            />
        );    
    }
}

export default EntityImagesTable;