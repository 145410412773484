import React, { Component } from 'react';
import { Link } from "react-router-dom";
import EntityImages from '../common/entityImages';
import Grid from "../common/grid";
import InputCheckboxes from '../common/inputCheckboxes';

class MyProductsGrid extends Component {
    render() { 
        const {products} = this.props;
        // , sortColumn, onSort
        const productsDefinition = this.getGridDefinition();
        return (
            <Grid 
                gridClasses="pb-grid products-grid"
                data={products}
                gridDefinition={productsDefinition}
            />
        );    
    }

    getGridDefinition() {
        const gridDefinition = [
            {
                name: '',
                key: 'select-link',
                path: "select-link",
                content: product => <InputCheckboxes
                    name={"prod-select-"+product.uuid}
                    classes=''
                    label={''} 
                    options={[{_id:product.uuid, name:product.name}]}
                    onChange={this.props.onSelect} 
                    error={''}
                    helpId={''} 
                    helpText={''}
                    value={this.props.selectedProducts}
                />
            },
            {
                key: 'product-title-link',
                content: product =>
                <Link title="edit product" to={`/myproduct/edit/${product.uuid}`} tabIndex="0">
                    {product.productName}
                    <EntityImages entityType="product" category="product" entityId={product.uuid} className="grid-image"/>
                </Link>                
            },
            {
                key: 'product-price', 
                class: "product-price",
                path: 'price',
                format:'currency'
            },
            {
                key: 'published', 
                class: "product-yesno",
                content: product => product.published && product.published === true ? "published" : "not published"
            },
            {   key: 'in-stock',
                class: "product-yesno",
                content: product => product.quantityAvailable && product.quantityAvailable > 0 ? "in stock" : "out of stock"
            }
        ]
        return gridDefinition;
    }
}

export default MyProductsGrid;