import React from 'react';

const TextBlock = ({message, classes}) => {
  const divClasses = ("text-block-div " + classes).trim();  
  return (
    <div className={divClasses}>
        {message}
    </div>
  );
};

export default TextBlock;