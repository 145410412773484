import React, { Component } from 'react';
import Table from "../common/table";
import Pagination from '../common/pagination';
import { toast } from 'react-toastify';
import * as adminService from '../../services/adminService';
import { Link } from "react-router-dom";
import SearchBox from '../common/searchBox';
import {formatTimestamp} from "../../utils/format.js"

class DashboardOrderTable extends Component {
    
    state = {  
        orderData: [],
        searchValue: '',
        limit: 10,
        sortColumn: {path:"updated", order:"desc"},
        init: false
    } 

    async componentDidMount() {
        if(this.state.init) {
            return;
        }
        const orderData = await this.getData(0, this.state.limit, this.state.sortColumn.path, this.state.sortColumn.order,  '');
        this.setState({orderData,init:true});
    }

    getData = async(start, limit, sortColumn, sortOrder, query) => {
        let orderData = [];
        try {
            let response = await adminService.getDashboardData('order',query,start,limit,sortColumn,sortOrder);
            if(response.data) {
                orderData = response.data;
            }
        } catch(exception) {
            if(exception.response.data) {
                const msg = exception.response.data.message ? exception.response.data.message : "an unhandled exception occurred";
                toast.error(msg);
            } else {
                toast.error("an unhandled exception occurred");
            }
        }
        return orderData;
    }

    handleFilterByPage = async (page) => {    
        const pageInfo = this.state.orderData.pageInfo;
        pageInfo.currentPage = page;
        const start = pageInfo.pageSize * (page-1);
        
        const orderData = await this.getData(start, pageInfo.pageSize, this.state.sortColumn.path, this.state.sortColumn.order,this.state.searchValue);
        this.setState({orderData});
    }
    
    handleSortByColumn = async sortColumn => { 
        const pageInfo = this.state.orderData.pageInfo;
        const orderData = await this.getData(pageInfo.start, pageInfo.pageSize, sortColumn.path, sortColumn.order,this.state.searchValue);
        this.setState({orderData,sortColumn});
    }

    handleSearch = async searchValue => {
        const pageInfo = this.state.orderData.pageInfo;
        const orderData = await this.getData(pageInfo.start, pageInfo.pageSize, this.state.sortColumn.path, this.state.sortColumn.order, searchValue);
        this.setState({searchValue,orderData});
    }

    render() { 
        const {orderData, searchValue, sortColumn} = this.state;
        const tableColumns = [
            {
                name: 'Store', 
                path: "storeName",
                //content: order => <Link to={`/business/${order.uuid}`}>{order.company.companyName}</Link>
            },
            {
                name: 'Order State', 
                path: 'orderState'
            },
            {
                name: 'Checkout State', 
                path: 'checkoutState'
            },
            {
                name: 'Count Items', 
                path: 'countItems',
                //content: order => order.verified === true ? "yes" : "no"
            },
            {
                name: 'Products Total', 
                path: 'productTotalPrice',
                //content: order => order.blocked === true ? "yes" : "no"
            },
            {
                name: 'Sales Tax',
                path: 'productSalesTax',
                //content: order => order.uploadsAllowed === true ? "yes" : "no"
            },
            {
                name: 'Shipping Total', 
                path: 'shippingTotalPrice',
                //content: order => order.blocked === true ? "yes" : "no"
            },
            {
                name: 'Processing Fee',
                path: 'processingFee',
                //content: order => order.uploadsAllowed === true ? "yes" : "no"
            },
            {
                name: 'Created',
                path: 'created',
                content: order => formatTimestamp(order.created, "sortabletimestamp")
            },
            {
                name: 'Updated',
                path: 'updated',
                content: order => formatTimestamp(order.updated, "sortabletimestamp")
            }
        ];

        if(orderData && orderData.pageInfo && null !== orderData.documents) {
            return (
                <React.Fragment>
                    <Pagination 
                        currentPage={orderData.pageInfo.currentPage}
                        itemsCount={orderData.pageInfo.rowCount} 
                        pageSize={orderData.pageInfo.pageSize}
                        onPageChange={this.handleFilterByPage} />  
                    <SearchBox value={searchValue} onChange={this.handleSearch} />  
                    <Table 
                        tableClasses="pb-table dashboard-table"
                        data={orderData.documents}
                        tableColumns={tableColumns}
                        sortColumn={sortColumn}
                        onSort={this.handleSortByColumn}
                    />
                </React.Fragment>
            );    
        } else {
            return (
                <React.Fragment>
                    <SearchBox value={searchValue} onChange={this.handleSearch} />
                    <p>No matching records.</p>
                </React.Fragment>
            );       
        }
    }
}

export default DashboardOrderTable;