import React from 'react';
//import {Link} from 'react-router-dom';
import Joi from 'joi-browser';
//import { toast } from 'react-toastify';
import InputForm from '../common/inputForm';

class MyProductVariationCreateForm extends InputForm {

    state = {
        variation: {},
        data: {
            variationName: '', 
            quantityAvailable: 0, 
            price: 0.00,
            taxable: "yes",
            taxRate: 0.00,
            description: '',
            sku: '',
            barcode: '',
            upc: '',
        },
        errors: {}
    };

    async componentDidMount() {
        const {product} = this.props;
        //doDisplayForm,product
        let variation = this.state.data;
        variation.upc = product.upc;
        variation.sku = product.sku;
        variation.taxable = product.taxable ? "yes" : "no";
        variation.taxRate = product.taxRate;
        variation.price = product.price;
        this.setState({variation});
    }
  
    schema = {
        _id: Joi.string(),
        variationName: Joi.string().required().label('Variation Name'),
        description: Joi.string().allow("").label('Description'),
        upc: Joi.string().allow("").label('Universal Product Code (UPC)'),
        sku: Joi.string().allow("").label('Stock Keeping Unit (SKU)'),
        barcode: Joi.string().allow("").label('Barcode'),
        quantityAvailable: Joi.number().min(0).required().label('Quantity Available'),
        price: Joi.number().min(0.0).required().label('Price'),
        taxable: Joi.string().required().label('Taxable'),
        taxRate: Joi.number().min(0.0).required().label('Tax Rate')
    };

    // moving to new async design pattern: 
    // https://reactjs.org/blog/2018/03/27/update-on-async-rendering.html
    // from DannyMoshe comment on https://stackoverflow.com/questions/47970276/is-using-async-componentdidmount-good

    handleSave = async(evt) => {
        evt.preventDefault();
        const variationData = this.normalizeData();
        this.props.handleSave(evt,variationData);
    }

    normalizeData() {
        let variationData = this.state.data;
        variationData.taxable = variationData.taxable === "yes" ? true : false;
        variationData.taxRate = parseFloat(variationData.taxRate);
        variationData.price = parseFloat(variationData.price);
        variationData.quantityAvailable = parseInt(variationData.quantityAvailable);
        return variationData;
    }

    render() {
        const yesNoOptions = [
            {"_id":"yes","name":"yes"},
            {"_id":"no","name":"no"}
        ];
        if(!this.props.doDisplayForm) {
            return('');
        }
        return (
            <div className="floating-div">
                <div className="row">
                    <div className="col">
                        <h1>Create Product Variation</h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <form onSubmit={this.handleSubmit}>
                            { // name, label, inputType, helpId, helpText, classes, options, onChange
                                this.renderInput(
                                "variationName", "Variation Name", "text", 
                                "", "", ""
                                )
                            }
                            {
                                this.renderInput(
                                "description", "Description", "textarea",
                                "", "", ""
                                )
                            }
                            {
                                this.renderInput(
                                "upc", "Universal Product Code (UPC)", "text", 
                                "", "", ""
                                )
                            }
                            {
                                this.renderInput(
                                "sku", "Stock Keeping Unit (SKU)", "text", 
                                "", "", ""
                                )
                            }
                            {
                                this.renderInput(
                                "barcode", "Barcode", "text", 
                                "", "", ""
                                )
                            }
                            {
                                this.renderInput(
                                "quantityAvailable", "QuantityAvailable", "text", 
                                "", "", ""
                                )
                            }
                            {
                                this.renderInput(
                                "price", "Price", "text", 
                                "", "", ""
                                )
                            }
                            {
                                this.renderInput(
                                "taxable", "Is this product taxable?", "radios", 
                                "taxableHelp", "If a product is taxable it will default to the store tax rate unless you change the tax rate.", 
                                "", yesNoOptions
                                )
                            }
                            {
                                this.renderInput(
                                "taxRate", "Tax Rate (%)", "text", 
                                "taxRateHelp", "If the item is marked taxable and no tax rate is specified, the store's default tax rate will be used", ""
                                )
                            }
                            <button className="btn btn-dark btn-rightspace float-left" onClick={(evt) => this.handleSave(evt)}>Save</button>
                            <button className="btn btn-danger btn-rightspace float-left" onClick={this.props.handleCancel}>Cancel</button>
                        </form>
                    </div>
                </div>
            </div>
        )   
    }
}

export default MyProductVariationCreateForm;