import http from "./httpService";
import config from "../config.json";
import {getProduct} from './productService';
//import { options } from "joi-browser";
const orderApiRouteBase = config.orderApiRouteBase;

export async function addToCart(product, variation) {

    const existingCart = await getCart();
    let cart = await getEmptyCart(product, variation);
    let uuid = "";
    let cartId = null;
    if(existingCart) {
        uuid = existingCart.userUuid;
        cartId = existingCart.uuid;
    }    

    if(null !== cartId && cartId.length > 0) {
        try {
            // don't trust the locally stored cart, get it from the server
            let response = await http.get(`${orderApiRouteBase}cart/${cartId}?uuid=${uuid}`);
            cart = response.data;
            
            const p = cart.orderItems.find(x => x.orderProduct.product.uuid === product.uuid 
                && x.orderProduct.variation.uuid === variation.uuid);
            if(!p) {
                const item = {
                    orderProduct: {
                        product: product,
                        quantity: 1
                    }
                };

                // look up the variations for this product
                // this function may be called by the product search results page
                // which won't have product variation data
                const originalProduct = await getProduct(product.uuid);
                const productVariations = originalProduct.variations;                
                if(variation && variation.uuid && variation.uuid.length > 0) {
                    item.orderProduct.variation = variation;
                    // for (var i=0;i<productVariations.length;i++) {
                    //     if(productVariations[i].uuid === variation.uuid) {
                    //         item.orderProduct.variation = variation;
                    //     }
                    // }        
                } else {
                    // pick the first one
                    if(productVariations &&  productVariations.length) {
                        item.orderProduct.variation = productVariations[0];
                    }
                }
                cart.orderItems.push(item);
            }
        }
        catch(ex) {
            //@ignore the error, maybe we didn't save yet; use the default cart
        }
    }
    else {
        cartId = "new";
    }

    await saveCart(cart, cartId);
    return true;
}

export async function removeFromCart(product,variation) {
    let cart = await getCart();
    let idx = getIndexOfPvInCart(cart, product, variation);
    if(idx > -1) {
        cart.orderItems.splice(idx, 1);
        await saveCart(cart, cart.uuid);
        return true;
    }
    return false;
}

export async function getCart(start, limit) {
    const cart = getLocalCart();
    let gotCart = false;
    let response = {};
    if(null !== cart) {
        const {uuid, userUuid} = cart;
        // if we have a cart but no user, return null
        // const jti = localStorage.getItem("jti");
        // console.log("jti",jti);
        // if(uuid && !jti) {
        //     return null;
        // }
        response = await http.get(`${orderApiRouteBase}cart/${uuid}?uuid=${userUuid}&start=${start}&limit=${limit}`);
        gotCart = true;
    } else {
        try {
            const jti = localStorage.getItem("jti");
            if(null !== jti) {
                response = await http.get(`${orderApiRouteBase}cart?start=${start}&limit=${limit}`);
                gotCart = true;
            }
        } catch(ex) {
        }
    }
    if(gotCart && response.data && response.data.orderItems) {
      // set price at the orderitem level, so that the $ is correct whether product or variation
      const orderItems = response.data.orderItems;
      for (var i=0;i<orderItems.length;i++) {
        let productPrice = parseFloat(orderItems[i].orderProduct.product.price);
        if(orderItems[i].orderProduct.variation && orderItems[i].orderProduct.variation.uuid && orderItems[i].orderProduct.variation.uuid.length > 0) {
            productPrice = parseFloat(orderItems[i].orderProduct.variation.price);
        }
        response.data.orderItems[i].orderProduct.price = productPrice;
      }
      return response.data;
    }
    return null;
}

export function getLocalCart() {
    // get the string from local storage and return it as an object
    const cartString = localStorage.getItem("cart");
    if(cartString) {
        return JSON.parse(cartString);
    }
    return null;
}

export async function saveLocalCart(cart) {
    // save a cart object as a string to local storage
    localStorage.setItem("cart", JSON.stringify(cart));
}

export async function saveCart(cart, cartId) {
    const response = await http.put(`${orderApiRouteBase}cart/${cartId}?uuid=${cart.userUuid}`, cart);
    cart = response.data;
    await saveLocalCart(cart);
    return response;
}

export async function setPaymentMethod(cart) {
    console.log("setting payment method", cart);
    const response = await http.post(`${orderApiRouteBase}cart/paymentmethod/${cart.uuid}`, cart);
    console.log("response",response);
    cart = response.data;
    await saveLocalCart(cart);
    return response;
}

export async function getCustomerOrders(start, limit) {
    const uuid = localStorage.getItem("user");
    if(null !== uuid && uuid.length > 0) {
        let response = await http.get(`${orderApiRouteBase}orders/customer/${uuid}?start=${start}&limit=${limit}`);
        return response && response.data ? response.data : {};
    }
    return {};
}

export async function getCustomerOrder(orderId) {
    const uuid = localStorage.getItem("user");
    if(null !== uuid && uuid.length > 0) {
        let response = await http.get(`${orderApiRouteBase}orders/${orderId}`);
        if (response.data.userUuid === uuid) {
            return response.data;
        }
    }
    return {};
}

export function organizeCartByStore(cart) {
    let stores = [];
    if (cart && cart.orderItems) {
        //console.log("cart",cart);
        for (var i=0;i<cart.orderItems.length;i++) {
            let storeIdx = -1;
            let item = cart.orderItems[i];
            for (var j=0; j<stores.length; j++) {
                if(stores[j].uuid === item.storeUuid) {
                    storeIdx = j;
                }
            }
            if(storeIdx === -1) {
                stores.push({
                    uuid:item.storeUuid,
                    storeName:item.storeName,
                    productTotalPrice:0.00,
                    productSalesTax:0.00,
                    shippingTotalPrice:0.00,
                    shippingSalesTax:0.00,
                    orderItems:[]
                });
                storeIdx = stores.length - 1;
            }
            stores[storeIdx].orderItems.push(item);
            stores[storeIdx].productTotalPrice += item.productTotalPrice;
            stores[storeIdx].productSalesTax += item.productSalesTax;
            stores[storeIdx].totalPrice += item.productTotalPrice + item.productSalesTax + item.shippingTotalPrice + item.shippingSalesTax;
        }
        cart.stores = stores;
        //console.log(cart);
    }
    return cart;
}

export async function saveShippingAddress(address) {

    let cart = await getCart();
    const cartId = cart.uuid;
    // console.log("cart id", cartId);
    cart.shippingAddress = {
        "country": "USA",
        "state": address.state,
        "city": address.city,
        "zipPlus4": address.zipPlus4,
        "addressLabel": address.addressLabel,
        "address1": address.address1,
        "address2": address.address2,
    };
        // "zipCode": 0,
        // "zip4": 0,
        //cart.checkoutState="shipto";
    let response = await saveCart(cart, cartId);
    return response;
}

export async function getShippingOptionsFromCart(cart) {
    let allOptions = [];
    for(var idx=0; idx<cart.storeShipping.length; idx++) {
        const item = cart.storeShipping[idx];
        let options = [{_id:'', name: ''}];
        if(item.shippingOptions.length) {
            for(var pdx=0; pdx<item.shippingOptions.length; pdx++) {
                const shipOption = item.shippingOptions[pdx];
                options.push(
                    {
                        _id: pdx,
                        name: shipOption.shipperName + " $" + shipOption.costEstimate
                    }
                );
            }    
        }
        let selectedOption = '';
        if(item.shipping && item.shipping.uuid.length > 0) {
            selectedOption = item.shipping.uuid.length;
        }
        allOptions.push({
            uuid: item.storeUuid,
            storeName: item.storeName,
            selectedOption: selectedOption,
            options: options
        });        
    }
    return allOptions;
}

export async function saveShippingOptions(options) {
    
    if(options) {
        let cart = await getCart();
        const cartId = cart.uuid;

        for(var idx=0; idx<cart.storeShipping.length; idx++) {
            const item = cart.storeShipping[idx];
            const storeKey = "store-" + item.storeUuid;
            if(options[storeKey]) {
                const optionIdx = parseInt(options[storeKey]);
                const shipOption = item.shippingOptions[optionIdx];
                cart.storeShipping[idx].shipping = shipOption;
            }
        }
        //cart.checkoutState="shipping";
        await saveCart(cart, cartId);    
    }
    return true;
}

export async function finalize() {
    let cart = await getCart();
    const cartId = cart.uuid;
    //@todo! trap for error checking out; return false if no checkout
    await http.put(`${orderApiRouteBase}cart/checkout/${cartId}`, cart);
    localStorage.removeItem("cart");
    return true;
}

export function getCartTotalProducts() {
    const c = getLocalCart();
    let qty = 0;
    if (c && c.orderItems && c.orderItems.length) {
        for (var i=0;i<c.orderItems.length;i++) {
            qty += c.orderItems[i].orderProduct.quantity;
        }
    }
    return qty;
}

// export async function getCartProductCount(product) {
//     const c = await getCart();
//     let count = 0;
//     if (c && c.orderItems) {
//         for (var i=0;i<c.orderItems.length;i++) {
//             if (c.orderItems[i].orderProduct.product.uuid === product.uuid) {
//                 count = c.orderItems[i].orderProduct.quantity;
//             }
//         }
//     }
//     return count;
// }

export async function incrementProduct(product,variation,productCount) {
    let cart = await getCart();
    let idx = getIndexOfPvInCart(cart, product, variation);
    if(idx > -1) {
        let newAmount = Number(productCount);       
        if(newAmount <= 0) {
            // remove the product from the cart
            await removeFromCart(product,variation);
            newAmount = 0;
        }
        else {
            const cartId = cart.uuid;            
            cart.orderItems[idx].orderProduct.quantity = newAmount;
            await saveCart(cart, cartId);
        }
        return newAmount;
    }
    return 0;
}

function getIndexOfPvInCart(cart, product, variation) {
    let idx = -1;
    if (cart && cart.orderItems) {
        if(variation && variation.uuid && variation.uuid.length > 0) {
            for (var i=0;i<cart.orderItems.length;i++) {
                if (cart.orderItems[i].orderProduct.product.uuid === product.uuid
                    && cart.orderItems[i].orderProduct.variation.uuid === variation.uuid) {
                    idx = i;
                }
            }    
        } else {
            for (var j=0;j<cart.orderItems.length;j++) {
                if (cart.orderItems[j].orderProduct.product.uuid === product.uuid) {
                    idx = j;
                }
            }
        }
    }
    return idx;
}

async function getEmptyCart(product, variation) {    
    let uuid = localStorage.getItem("user");
    if(!uuid) { 
        uuid = ""
    }
    const cart = {
        userUuid: uuid,
        canceled: false, 
        orderState: "created",
        orderItems: [
            { 
                merchantUuid: product.merchantUuid,
                storeUuid: product.storeUuid,
                orderProduct: {
                    product: product,                    
                    quantity: 1,   
                },
                canceled: false
            }
        ]
    };

    // look up the variations for this product
    // this function may be called by the product search results page
    // which won't have product variation data
    const originalProduct = await getProduct(product.uuid);
    const productVariations = originalProduct.variations;
    if(variation && variation.uuid && variation.uuid.length > 0) {
        for (var i=0;i<productVariations.length;i++) {
            if(productVariations[i].uuid === variation.uuid) {
                cart.orderItems[0].orderProduct.variation = variation;
            }
        }        
    } else {
        // pick the first one
        if(productVariations &&  productVariations.length) {
            cart.orderItems[0].orderProduct.variation = productVariations[0];
        }
    }
    return cart;
}