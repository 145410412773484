import React from 'react';
//import {Link} from 'react-router-dom';
import Joi from 'joi-browser';
import { toast } from 'react-toastify';
import InputForm from '../common/inputForm';
import EntityImages from './entityImages';
import MyShippingOptions from './myShippingOptions';
import PhysicalDimensions from './../common/physicalDimensions';
import LocationEditForm from './../common/locationEditForm';

class MyProductVariationEditForm extends InputForm {

    state = {
        variation: null,
        shippingOptions: null,
        data: {
            variationName: '', 
            quantityAvailable: 0, 
            price: 0.00,
            taxable: true,
            taxRate: 0.00,
            description: '',
            sku: '',
            barcode: '',
            upc: '',
        },
        currentTab: '',
        displayTabs: [],
        errors: {}
    };

    async componentDidMount() {
        let {variation,product} = this.props;
        let shippingOptions = null;
        let displayTabs = [];
        let currentTab = '';
        try {
            if(!variation) {
                variation = this.state.data;
                variation.upc = product.upc;
                variation.sku = product.sku;
            }

            if(variation.shippingOptions) {
                shippingOptions = variation.shippingOptions;
            }           

            currentTab = "variation-" + variation.uuid;
            displayTabs.push({
                type: "variation",
                data: variation,
                classes: "variation active",
                label: "Details",
                key: currentTab
            });
            displayTabs.push({
                type: "variation-images",
                data: variation,
                classes: "variation",
                label: "Images",
                key: currentTab + "-images"
            });
            displayTabs.push({
                type: "variation-dimensions",
                data: variation,
                classes: "variation",
                label: "Dimensions",
                key: currentTab + "-dimensions"
            });
            displayTabs.push({
                type: "variation-shipping",
                data: variation,
                classes: "variation",
                label: "Shipping",
                key: currentTab + "-shipping"
            });
            displayTabs.push({
                type: "variation-mfrLocation",
                data: variation,
                classes: "variation",
                label: "Manufacture Location",
                key: currentTab + "-mfrLocation"
            });
        }
        catch(exception) {
            if(exception.response && exception.response.status === 404) {
                // redirect to the Notfound page
                // replace the history so that clicking the back button 
                // goes to the last page prior to the one with the bad variation id
                return this.props.history.replace('/notfound');
            }
            else {
                console.log(exception);
                toast.error('An unhandled exception occurred!');
            }
        }
        this.setState({ variation, data: this.mapToViewModel(variation),
            shippingOptions, displayTabs, currentTab});
    }
  
    schema = {
        _id: Joi.string(),
        variationName: Joi.string().required().label('Variation Name'),
        description: Joi.string().allow("").label('Description'),
        upc: Joi.string().allow("").label('Universal Product Code (UPC)'),
        sku: Joi.string().allow("").label('Stock Keeping Unit (SKU)'),
        barcode: Joi.string().allow("").label('Barcode'),
        quantityAvailable: Joi.number().min(0).required().label('Quantity Available'),
        price: Joi.number().min(0.0).required().label('Price'),
        taxable: Joi.string().required().label('Taxable'),
        taxRate: Joi.number().allow("").min(0.0).label('Tax Rate')
    };

    // moving to new async design pattern: 
    // https://reactjs.org/blog/2018/03/27/update-on-async-rendering.html
    // from DannyMoshe comment on https://stackoverflow.com/questions/47970276/is-using-async-componentdidmount-good

    mapToViewModel(variation) {
        return {
            _id: variation.uuid,
            variationName: variation.variationName,
            quantityAvailable: variation.quantityAvailable,
            published: variation.published === true ? "yes" : "no",
            price: variation.price,
            taxable: variation.taxable === true ? "yes" : "no",
            taxRate: variation.taxRate,
            upc: variation.upc,
            sku: variation.sku,
            barcode: variation.barcode,
        };
    }

    handleSave = async(evt) => {
        evt.preventDefault();
        let variationData = this.state.variation;
        variationData = this.normalizeVariationData(variationData);
        this.props.handleSave(evt,variationData);
    }

    normalizeVariationData(variation) {
        let variationData = variation; // default to the values in our variation entity
        const data = this.state.data;

        // update values from the form values
        variationData.published = data.published === "yes" ? true : false;
        variationData.taxable = data.taxable === "yes" ? true : false;

        variationData.variationName = data.variationName;
        variationData.upc = data.upc;
        variationData.sku = data.sku;
        variationData.barcode = data.barcode;

        variationData.taxRate = parseFloat(data.taxRate);
        variationData.price = parseFloat(data.price);
        variationData.quantityAvailable = parseInt(data.quantityAvailable);

        return variationData;
    }

    handleTabClick = async (evt) => {
        const tabId = evt.target.id;
        const divId = tabId.replace("tab-","");
        this.setState({currentTab:divId})
    }
    handleSaveDimension = async(evt,dimensions) => {
        let variation = this.state.variation;
        variation.dimensions = dimensions;
        const variationData = this.normalizeVariationData(variation);
        this.props.handleSave(evt,variationData);
    }
    handleCancelMfrLocation = async() => {
    }
    handleSaveMfrLocation = async(evt,location) => {
        let variation = this.state.variation;
        variation.mfrLocation = []
        variation.mfrLocation.push(location);
        const variationData = this.normalizeVariationData(variation);
        // cannot unmarshal object into Go struct field Variation.
        // variations.mfrLocation of type []structure.Location
        this.props.handleSave(evt,variationData);
    }
    handleSaveShippingOptions = async(evt,shippingOptions) => {
        let variation = this.state.variation;
        variation.shippingOptions = shippingOptions;
        const variationData = this.normalizeVariationData(variation);
        this.props.handleSave(evt,variationData);
    }

    // functions for rendering
    getComponent(dt) {
        const {variation,shippingOptions} = this.state;
        const {merchant,store,product} = this.props;
        if(!variation) {
            return('');
        }

        let loc = {
            country:"",
            state:"",
            city:"",
            neighborhood:""
        };
        if(variation.mfrLocation && variation.mfrLocation.length) {
            const d = variation.mfrLocation[0];
            loc = {
                country: d.country ? d.country : "",
                state: d.state ? d.state : "",
                city: d.city ? d.city : "",
                neighborhood: d.neighborhood ? d.neighborhood : "",
            }
        } 

        const yesNoOptions = [
            {"_id":"yes","name":"yes"},
            {"_id":"no","name":"no"}
        ];
        if(dt.type === "variation-images") {
            //return (this.renderBannerImageForm());
            return(
                <EntityImages 
                storeUuid={store.uuid}
                parentType="variation"
                parentUuid={variation.uuid}
                parentEntity={variation.variationName}
                category="variation"
                merchant={merchant}
                />
            );
        }
        else if (dt.type === "variation-dimensions") {
            return (
                <PhysicalDimensions
                    dimensions={variation.dimensions}
                    handleSave={this.handleSaveDimension}
                />
            );
        }
        else if (dt.type === "variation-shipping") {
            return (
                <MyShippingOptions
                    shippingOptions={shippingOptions}
                    store={store}
                    product={product}
                    variation={variation}
                    handleClone={this.handleCloneOptions}
                    handleSave={this.handleSaveShippingOptions}
                />
            );
        }
        else if (dt.type === "variation-mfrLocation") {            
            return(<LocationEditForm 
                location={loc}
                handleSave={this.handleSaveMfrLocation}
                handleCancel={this.handleCancelMfrLocation}
                includeCountry={true}
                includeHood={false}
            />);
        }
        else {
            return (
                <div className="row">
                    <div className="col">
                        <form onSubmit={this.handleSubmit}>
                            { // name, label, inputType, helpId, helpText, classes, options, onChange
                                this.renderInput(
                                "variationName", "Variation Name", "text", 
                                "", "", ""
                                )
                            }
                            {
                                this.renderInput(
                                "description", "Description", "textarea",
                                "", "", ""
                                )
                            }
                            {
                                this.renderInput(
                                "upc", "Universal Product Code (UPC)", "text", 
                                "", "", ""
                                )
                            }
                            {
                                this.renderInput(
                                "sku", "Stock Keeping Unit (SKU)", "text", 
                                "", "", ""
                                )
                            }
                            {
                                this.renderInput(
                                "barcode", "Barcode", "text", 
                                "", "", ""
                                )
                            }
                            {/* {
                                this.renderInput(
                                "genreId", "Genre", "select", 
                                "", "", "", this.state.allStores
                                )
                            } */}
                            {
                                this.renderInput(
                                "quantityAvailable", "QuantityAvailable", "text", 
                                "", "", ""
                                )
                            }
                            {
                                this.renderInput(
                                "price", "Price", "text", 
                                "", "", ""
                                )
                            }
                            {
                                this.renderInput(
                                "taxable", "Is this product taxable?", "radios", 
                                "taxableHelp", "If a product is taxable it will default to the store tax rate unless you change the tax rate.", 
                                "", yesNoOptions
                                )
                            }
                            {
                                this.renderInput(
                                "taxRate", "Tax Rate (%)", "text", 
                                "taxRateHelp", "If the item is marked taxable and no tax rate is specified, the store's default tax rate will be used", ""
                                )
                            }

                            <button className="btn btn-dark btn-rightspace float-left" onClick={(evt) => this.handleSave(evt)}>Save</button>
                        </form>
                    </div>
                </div>
            );
        }
    }
    getClasses(keyName) {
        let classes = "nav-link";
        if(keyName === this.state.currentTab) {
            classes += " active"
        }
        return classes;
    }
    getTabClasses(keyName) {
        let classes = "tab-pane variation-tab-pane";
        if(keyName === this.state.currentTab) {
            classes += " active"
        }
        return classes;
    }

    render() {
        const {variation,displayTabs} = this.state;
        return (
            <React.Fragment>
                <div className="row">
                    <div className="col">
                        <h2>{variation && variation.variationName + " - "}Edit Variation</h2>
                        <button className="btn btn-danger m-2 float-right" onClick={(evt) => this.props.handleDelete(evt,this.state.data)}>Delete</button>
                    </div>
                </div>
                <div className="tab-ui store" role="navigation">
                    <nav className="nav nav-pills nav-justified">
                        { displayTabs.map(ds => 
                                <div data-toggle='tab' onClick={this.handleTabClick} id={"tab-" + ds.key} key={"tab-" + ds.key} className={this.getClasses(ds.key)} href={'#' + ds.key}>{ds.label}</div>
                            )
                        }
                    </nav>
                    <div className="tab-content">
                        { displayTabs.map(ds => 
                            <div id={ds.key} key={ds.key} className={this.getTabClasses(ds.key)}>
                                {this.getComponent(ds)}
                            </div>
                        )
                        }
                    </div>
                </div>
            </React.Fragment>
        )   
    }
}

export default MyProductVariationEditForm;