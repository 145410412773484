import React, { Component } from 'react';
import { formatDate } from '../../services/displayFormat';
import _ from 'lodash';

class TableBody extends Component {
    
    getDatumId = (datum) => {
        // _id value may be numeric or may be a string
        let datumId = (undefined !== datum._id && null !== datum._id) ? `${datum._id}` : null;
        if(null == datumId) {
            datumId = null !== datum.uuid ? datum.uuid : null;
        }
        if(null == datumId) {
            datumId = null !== datum.id ? datum.id : '';
        }
        return datumId;
    }

    renderCell = (datum, column) => {
        let val = '';
        if(column.content) {
            val = column.content(datum); 
        }
        else {
            val = _.get(datum,column.path);
        }
        if(column.format) {
            if(column.format === "timestamp") {
                return formatDate(val);    
            } else if (column.format === "currency") {
                val = '$' + parseFloat(val).toFixed(2);                    
            } else if (column.format === "decimal") {
                val = parseFloat(val).toFixed(2);                    
            }
        }
        return val;
    }
    createKey = (datum, column) => {
        let datumId = this.getDatumId(datum);
        if(column.path && column.path.length) {
            datumId += "_" + column.path;
        } else if (column.key) {
            datumId += "_" + column.key;
        }
        return datumId;
    }

    getClass(datum, classField) {
        if(classField.length && datum[classField]) {
            return datum[classField];
        }
        return '';
    }

    render() { 
        const {data, tableColumns} = this.props;
        const classField = this.props.classField? this.props.classField : '';
        if(undefined === data || !data || data.length < 1) {
            return (
                <tbody></tbody>
            );
        }

        return (
            <tbody>
            {
                data.map(datum => 
                    <tr key={this.getDatumId(datum)} className={this.getClass(datum, classField)}>
                    { 
                        tableColumns.map(column => 
                            <td key={this.createKey(datum, column)} className={column.class ? column.class : ""}>{this.renderCell(datum, column)}</td>
                        )
                    }
                </tr>
                )
            }
            </tbody>
        );
    }

}

export default TableBody;



