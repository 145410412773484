import React, { Component } from 'react';
//import { Link } from "react-router-dom";
import Table from "../common/table";
import InputCheckboxes from '../common/inputCheckboxes';

class MyShippingOptionsTable extends Component {

    render() { 
        const {shippingOptions, sortColumn, onSort, onSelect, onClickEdit, selectedOptions} = this.props;
        const tableColumns = [
            {
                name: '',
                key: 'select',
                path: "select-link",
                content: shippingOption => <InputCheckboxes
                    name={"option-select-"+shippingOption._id}
                    classes=''
                    label={''} 
                    options={[{_id:shippingOption._id, name:shippingOption.name}]}
                    onChange={(evt) => onSelect(evt) }
                    error={''}
                    helpId={''} 
                    helpText={''}
                    value={selectedOptions}
                />
            },
            {
                name: '', 
                key: 'edit',
                path: "edit-link",                
                content: shippingOption =>
                    <React.Fragment><button className="btn btn-success btn-sm" onClick={(evt) => onClickEdit(evt,shippingOption)}>Edit</button>
                    {shippingOption.shipperName}</React.Fragment>
            },
            {name: 'Description', path: 'description'},
            {name: 'Cost Estimate', path: 'costEstimate', format:'currency', class: "code"},
            {name: 'Arrival Days Estimate', path: 'arrivalDaysEstimate', class: "code" },
        ];
        return (
            <Table 
                tableClasses="pb-table shippingoptions-table"
                data={shippingOptions}
                tableColumns={tableColumns}
                sortColumn={sortColumn}
                onSort={onSort}
            />
        );    
    }
}

export default MyShippingOptionsTable;