import React, { Component } from 'react';

class About extends Component {
    render() {
        return (
            <React.Fragment>
                <div className="row">
                    <div className="col">
                        <h1>About</h1>
                        <p>Planet B Market provides one-stop shopping for local and small USA-based businesses.</p>
                        <p>Planet B Market is based in Baltimore, Maryland.</p>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default About;