import React, { Component } from 'react';
import { getImagesData, getImage } from '../../services/imageService';
var Carousel = require('react-responsive-carousel').Carousel;

class EntityImages extends Component {

    state = {
        imagesData: [],
        pageInfo: {
            rowCount: 0,
            pageCount: 0,
            pageSize: 20,
            currentPage: 0
        },
        init: false,
    };

    async componentDidMount() {
        if(this.state.init) {
            return;
        }
        const {entityType, category, entityId} = this.props;
        const existingImage = {
            "parentType": entityType,
            "category": category,
            "parentUuid": entityId
        };
        const imagesResults = await getImagesData(existingImage);
        let imagesData = imagesResults && imagesResults.documents ? imagesResults.documents : [];
        if(imagesData && imagesData.length > 0) {
            for (var i=0;i<imagesData.length;i++) {
                imagesData[i].data  = await getImage(imagesData[i].uuid);
            }
            this.setState({imagesData, pageInfo:imagesResults.pageInfo,init:true});
        }
    }

    onImageChange = async image => {
        //const pageInfo = this.state.userData.pageInfo;
        //const userData = await this.getData(pageInfo.start, pageInfo.pageSize, this.state.sortColumn.path, this.state.sortColumn.order, searchValue);
        //this.setState({searchValue, userData});
        console.log(image);
    }
    onImageClickItem = async image => {
        //const pageInfo = this.state.userData.pageInfo;
        //const userData = await this.getData(pageInfo.start, pageInfo.pageSize, this.state.sortColumn.path, this.state.sortColumn.order, searchValue);
        //this.setState({searchValue, userData});
        console.log(image);
    }
    onImageClickThumb = async image => {
        //const pageInfo = this.state.userData.pageInfo;
        //const userData = await this.getData(pageInfo.start, pageInfo.pageSize, this.state.sortColumn.path, this.state.sortColumn.order, searchValue);
        //this.setState({searchValue, userData});
        console.log(image);
    }

    render() { 
        const {imagesData} = this.state;
        const {className} = this.props;
        if(null === imagesData || imagesData.length === 0) {
            return (
                <div className={className}>
                    <img src="/default.jpg" alt="placeholder" />
                </div>
            );
        }

        if(imagesData.length === 1) {
            return(
                <div className={className}>
                    <img src={imagesData[0].data} alt={imagesData[0].title} />
                </div>
            );
        }

        return (
            <div className={className}>
                <Carousel showArrows={true} onChange={this.onImageChange} onClickItem={this.onImageClickItem} onClickThumb={this.onImageClickThumb}>
                {
                    imagesData.map(img => 
                        <div>
                            <img src={img.data} />
                            <p className="legend">{img.image.alt}</p>
                        </div>
                    )
                }
            </Carousel>
            </div>
        );
    }
}
 
export default EntityImages;