import React, { Component } from 'react';
//import Joi, { errors } from 'joi-browser';
import Joi from 'joi-browser';
import Input from './input';
import InputTextarea from './inputTextarea';
import InputSelect from './inputSelect';
import InputRadios from './inputRadios';
import InputCheckboxes from './inputCheckboxes';
import PopWait from './popWait';

class InputForm extends Component {
    state = {
        inputs: [],
        data: {},
        errors: {},
        schema: [],
        working: false
    };

    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    validateProperty = (input) => {
        const {name, value} = input;   
        const objToValidate = { [name]:value};

        const thisSchema = this.state.schema ? this.state.schema : this.schema;
        const inputSchema = { [name]: thisSchema[name] };
    
        const {error} = Joi.validate(objToValidate, inputSchema);
    
        let message = null;        
        if(error) {
            // this is necessary to override the gross message
            // that will be displayed for fields requiring a regex match
            const customMsg = input.getAttribute('data-errortext');
            if(customMsg) {
                message = customMsg;
            }
            else {
                message = error.details[0].message;
            } 
        }
        //return !error ? null : error.details[0].message;
        return message
    }
    
    validate = () => {
    
        // by default, the abortEarly option is set to true
        // this causes the validation to return at the first failure
        // set abortEarly to false to get all errors
        const options = {abortEarly: false};
        const thisSchema = this.state.schema ? this.state.schema : this.schema;
        const result = Joi.validate(
            this.state.data, 
            thisSchema, 
            options
        );
        if(!result.error) {
            // console.log("returning null")
            return null;
        };
    
        const errors = {};
        for (let item of result.error.details)
            errors[item.path[0]] = item.message;
    
        // const {username, password} = this.state.data;
        
        // if(username.trim() === '') 
        //     errors.username = 'Username is required';
        
        // if(password.trim() === '') 
        //     errors.password = 'Password is required';

        //console.log("all errors",errors);
        return errors;
    }

    // handleChange = (e) => {
    // instead destructure the properties of the event to make it more readable    
    handleChange = ({currentTarget: input}) => {
        const data = {...this.state.data};
        const errors = {...this.state.errors};

        // validate the user's text
        const errorMessage = this.validateProperty(input);
        if(errorMessage) 
            errors[input.name] = errorMessage;
        else 
            delete errors[input.name];

        //data[e.currentTarget.name] = e.currentTarget.value;
        // console.log("handling change");
        data[input.name] = input.value;
        // console.log("input",input.name);
        // console.log("input",input.value);
        // if(input.type === "radio" || input.type === "checkbox") {
        //     if(input.checked === true) {
        //         data[input.name] = input.value;
        //     }
        // } else {
        //     data[input.name] = input.value;
        // }

        this.setState({data, errors});
    }

    handleSubmit = e => {
        e.preventDefault();

        // validate the user's submission
        const errors = this.validate();
        // so if errors is null set an empty object
        this.setState({working: true, errors: errors || {}});
        if(errors) {
            //console.log("submit errors",errors);
            this.setState({working:false});
            return;
        }
        this.setState({working: true, errors: errors || {}});

        this.doSubmit(e);
    };

    renderInput(name, label, inputType, helpId, helpText, classes, options, onChange) {
        const {errors, data} = this.state;
        if(inputType === "text" || inputType === "password") {
            return (
                <Input 
                    type={inputType} 
                    placeholder={`Enter ${label}`}
                    label={label} 
                    name={name}
                    classes={classes}
                    error={errors[name]}
                    value={data[name]} 
                    onChange={undefined !== onChange ? onChange : this.handleChange} 
                    helpId={helpId} helpText={helpText}
                />
            );
        }
        else if (inputType === "textarea") {
            return (
                <InputTextarea 
                    placeholder={`Enter ${label}`}
                    label={label} 
                    name={name}
                    classes={classes}
                    error={errors[name]}
                    value={data[name]} 
                    onChange={undefined !== onChange ? onChange : this.handleChange} 
                    helpId={helpId} helpText={helpText}
                />
            );
        }
        else if (inputType === "select") {
            return(
                <InputSelect 
                    name={name}
                    classes={classes}
                    key={name}
                    value={data[name]} 
                    label={label} 
                    options={options}
                    onChange={undefined !== onChange ? onChange : this.handleChange} 
                    error={errors[name]}
                    placeholder={`Enter ${label}`}
                    helpId={helpId} helpText={helpText}
                />
            );
        }
        else if (inputType === "radios") {
            return(
                <InputRadios
                    name={name}
                    classes={classes}
                    key={name}
                    value={data[name]} 
                    label={label} 
                    options={options}
                    onChange={undefined !== onChange ? onChange : this.handleChange} 
                    error={errors[name]}
                    helpId={helpId} helpText={helpText}
                />
            );
        }
        else if (inputType === "checkboxes") {
            return(
                <InputCheckboxes
                    name={name}
                    classes={classes}
                    value={data[name]} 
                    label={label} 
                    options={options}
                    onChange={undefined !== onChange ? onChange : this.handleChange} 
                    error={errors[{name}]}
                    helpId={helpId} helpText={helpText}
                />
            );
        }
    }

    renderSubmitButton(label,className) {
        return(
            <React.Fragment>
                <button type="submit" disabled={this.validate()} className={className ? className + "btn btn-dark" : "btn btn-dark"}>{label}</button>
                <PopWait visible={this.state.working} message="Hang on a sec..."/>
            </React.Fragment>
       );
    }


}
 
export default InputForm;