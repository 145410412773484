import { format } from './format';

export function formatTimestamp(unixTimestamp, format) {

    let formattedDate = "";
    try {
        let dateObj = new Date(unixTimestamp * 1000);    
        const offset = dateObj.getTimezoneOffset(); // minutes diff from UTC
        dateObj = new Date(dateObj.getTime() - (offset*60*1000))

        switch(format) {
            case "date":
                formattedDate = dateObj.toISOString().split('T')[0];
                break;
            case "sortabletimestamp":     
                const dateParts = dateObj.toISOString().split('T');
                formattedDate = dateParts[0] + " " + dateParts[1];
                break;
            case "timestamp":
                formattedDate = new Intl.DateTimeFormat('en-US', 
                { 
                    year: 'numeric', 
                    month: '2-digit', 
                    day: '2-digit', 
                    hour: '2-digit', 
                    minute: '2-digit', 
                    second: '2-digit',
                    //timeZone: ''
                }).format(dateObj);
                break;
            default:
                formattedDate = dateObj.toISOString().split('T')[0]
                break;
        }

    }
    catch(ex) {
        console.log(ex);
    }

    return formattedDate;
}
