import React, { Component } from 'react';
import * as adminService from '../../services/adminService';
import { toast } from 'react-toastify';
import DashboardActivityTable from './dashboardActivityTable';
import DashboardMerchantTable from './dashboardMerchantTable';
import DashboardOrderTable from './dashboardOrderTable';
import DashboardUserTable from './dashboardUserTable';

class Dashboard extends Component {

    async indexProducts() {
        await adminService.indexProducts("true");
        toast.success("Products were indexed");        
    }

    render() { 
        return (
            <React.Fragment>
              <h1>Dashboard</h1>
                <div className="row">
                    <div className="col col-6">
                        {/* <a className="btn btn-primary" data-toggle="collapse" href="#activityTable" role="button" aria-expanded="false" aria-controls="activityTable">System Activity</a> */}
                        <button className="btn btn-primary" type="button" data-toggle="collapse" data-target="#activityTable" aria-expanded="false" aria-controls="activityTable">System Activity</button>
                        <button className="btn btn-primary" type="button" data-toggle="collapse" data-target="#merchantTable" aria-expanded="false" aria-controls="merchantTable">Merchants</button>
                        <button className="btn btn-primary" type="button" data-toggle="collapse" data-target="#userTable" aria-expanded="false" aria-controls="userTable">Users</button>
                        <button className="btn btn-primary" type="button" data-toggle="collapse" data-target="#orderTable" aria-expanded="false" aria-controls="orderTable">Orders</button>
                        {/* <button className="btn btn-primary" type="button" data-toggle="collapse" data-target=".multi-collapse" aria-expanded="false" aria-controls="multiCollapseExample1 multiCollapseExample2">Toggle both elements</button> */}
                    </div>
                    <div className="col col-6">
                        <button onClick={this.indexProducts} className="btn btn-success btn-sm float-right">Index products</button>
                    </div>
                </div>
                <div className="row">
                    <div className="col col-6 col-md-12">
                        <div className="collapse multi-collapse" id="activityTable">
                        <div className="card card-body">
                            <h2>Activity</h2>
                            <DashboardActivityTable />                    
                        </div>
                        </div>
                    </div>
                    <div className="col col-6 col-md-12">
                        <div className="collapse multi-collapse" id="merchantTable">
                            <div className="card card-body">
                                <h2>Merchants</h2>
                                <DashboardMerchantTable />
                            </div>
                        </div>
                    </div>
                    <div className="col col-6 col-md-12">
                        <div className="collapse multi-collapse" id="userTable">
                            <div className="card card-body">
                                <h2>Users</h2>
                                <DashboardUserTable />
                            </div>
                        </div>
                    </div>
                    <div className="col col-6 col-md-12">
                        <div className="collapse multi-collapse" id="orderTable">
                            <div className="card card-body">
                                <h2>Orders</h2>
                                <DashboardOrderTable />
                            </div>
                        </div>
                    </div>
                    <div className="col col-6 col-md-12">
                        <p>&nbsp; &nbsp;</p>
                    </div>
                </div>
            </React.Fragment>
          );  
    }
}
 
export default Dashboard;