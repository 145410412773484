import React, { Component } from 'react';
import { toast } from 'react-toastify';
// import Pagination from '../common/pagination';
// import _ from 'lodash';
import { saveImage, getImage, deleteImage, getImagesData } from '../../services/imageService';
import EntityImagesTable from './entityImagesTable';
import ImageEdit from './imageEdit';
import PopWait from '../common/popWait';

// Displays, and lets the user edit, image data for an entity
// e.g. for a store or for a specific product

class EntityImages extends Component {

    state = {
        storeUuid: '',
        parentUuid: '',
        parentType: '',
        category: '',
        images: [],
        pageInfo: {
          rowCount: 0,
          pageCount: 0,
          pageSize: 20,
          currentPage: 0
        },
        searchValue: null,
        sortColumn: {path:'shipperName',order:'asc'},
        init:false,
        selectedImages: [],
        currentImage: {},
        doDisplayForm: false,
        working: false
    }
  
  handleFilterByPage = (page) => {    
      this.setState({currentPage: page});
  }

  handleSortByColumn = sortColumn => {     
    this.setState({sortColumn});
  }
  
  handleEditClick = (evt, image) => {
    evt.preventDefault();
    const doDisplayForm = true;
    this.setState({doDisplayForm,currentImage:image});
  }

  handleNewClick = () => {
    const doDisplayForm = true;
    const currentImage = {
      _id:'',
      alt: ''
    };
    this.setState({doDisplayForm,currentImage});
  }

  handleSelectClick = ({currentTarget}) => {
    // get option id from input id 
    // id format for input is option-select-[id]_[id]
    const idParts = currentTarget.id.split("_");
    if(idParts.length < 2) {
      return;
    }

    const optionId = idParts[1];
    let selectedImages = this.state.selectedImages;
    let valuePos = -1;
    for (var i=0;i<selectedImages.length;i++) {
      if(selectedImages[i] === optionId) {
          valuePos = i;
      }
    }   

    if(currentTarget.checked) {
      if(valuePos === -1) {
        selectedImages.push(optionId)
      }
    } else {
      if(valuePos > -1) {
        selectedImages.splice(valuePos, 1);
      }
    }
    this.setState({selectedImages});
  }

  handleSaveImage = async(evt, imageData) => {
    evt.preventDefault();
    this.setState({working: true});

    let images = this.state.images;
    //let valuePos = -1;
    if(!imageData._id || imageData._id.length === 0) {
        const idx = images && images.length ? images.length : 0;
        imageData._id = `${idx}`; //`"${idx}"`;
    }
    // else {
    //     for (var i=0;i<images.length;i++) {
    //         if(images[i]._id === imageData._id) {
    //             valuePos = i;
    //         }
    //     }   
    // }

    //@todo error trapping
    await this.saveImage(evt, imageData);
    const {parentType, category, parentUuid} = this.props;
    const imagesResults = await this.loadImages(parentType, parentUuid, category, 0);
    const {documents, pageInfo} = imagesResults;
    this.setState({images:documents,pageInfo,currentImage:{},doDisplayForm:false,working:false});
  }    

  loadImages = async(parentType, parentUuid, category, start) => {
    const imageRequest = {
      "parentType": parentType,
      "category": category,
      "parentUuid": parentUuid
    };
    let imagesResults = {pageInfo: {}, documents: []};
    try {
      imagesResults = await getImagesData(imageRequest, start, this.state.pageInfo.pageCount);
      let images = imagesResults &&  imagesResults.documents ? imagesResults.documents : [];
      if(images && images.length > 0) {
        for (var i=0;i<images.length;i++) {
            images[i]._id = images[i].uuid;
            images[i].data  = await getImage(images[i].uuid);
        }
        imagesResults.documents = images;
      }
    } catch(exception) {
      //@todo
    }
    return imagesResults;
  }

  handleDeleteImages = async (evt) => {
    // when we're deleting, 
    // any option that doesn't have a valid uuid wasn't saved anyway
    evt.preventDefault();
    this.setState({working: true});

    let selectedImages = this.state.selectedImages;
    for (var i=0;i<selectedImages.length;i++) {            
      await deleteImage(selectedImages[i]);
      // const response = await deleteImage(selectedImages[i]);
      //@todo collect response errors if any, and display them
    }           
    const {parentType, category, parentUuid} = this.props;
    const imagesResults = await this.loadImages(parentType, parentUuid, category, 0);
    const {documents:images, pageInfo} = imagesResults;
    this.setState({images,pageInfo,selectedImages:[],working:false});
  }

  deleteOptionById(optionId, options) {      
    let valuePos = -1;  
    for (var idx=0;idx<options.length;idx++) {
        if(options[idx]._id === optionId) {
            valuePos = idx;
        }
    } 
    if(valuePos > -1) {
        options.splice(valuePos, 1);
    }
    return options;
  }

  saveImage = async(evt, imageData) => {
    // console.log(evt.target[1]);
    let response = {};
    if(evt.target.form[0]) {
        const {parentType,parentUuid,category,storeUuid} = this.state;
        try {
            const img = evt.target.form[0].files[0];
            let formData = new FormData();
            formData.append(
                "upload",
                img,
                img.name
            );
            formData.append('altText', evt.target.form[1].value); // or imageData.alt
            formData.append('uuid', imageData._id);
            formData.append('parentType', parentType);
            formData.append('parentUuid', parentUuid);
            formData.append('category', category);

            response = await saveImage(storeUuid, formData);
        }
        catch(exception) {
            toast.error("An unexpected error occurred!");
        }
    }        
    return response;
  }

  async componentDidMount() {
    const {parentType, category, parentUuid, storeUuid} = this.props;
    const imagesResults = await this.loadImages(parentType, parentUuid, category);
    const {documents:images, pageInfo} = imagesResults;
    this.setState({storeUuid,parentType,parentUuid,category,images,pageInfo,init:true});
  }

  constructor(props) {
      super(props);
      this.handleCancel = this.handleCancel.bind(this);
      this.handleSaveImage = this.handleSaveImage.bind(this);
      this.handleDeleteImages = this.handleDeleteImages.bind(this);
  }
    
    renderImages() {
      const {sortColumn, images, selectedImages, doDisplayForm, currentImage } = this.state;
        // searchValue, parentUuid
        return (
          <React.Fragment>
            <div className="row">
              <div className="col">
                <div className="content">
                  {this.getImagesButtons()}

                  {this.getImagesTable(images, selectedImages, sortColumn)}
                      
                </div>
              </div>
            </div>
            { this.renderEditForm(doDisplayForm,currentImage) }
            <PopWait visible={this.state.working} message="Hang on a sec..."/>
          </React.Fragment>
        );
    };

    handleCancel(evt) {
      evt.preventDefault();
      this.setState({doDisplayForm:false,currentImage:{}});
    }

    renderEditForm(doDisplayForm,imageData) {
        if(!doDisplayForm) {
            return '';
        }
        return(
            <div className="floating-div">
                <ImageEdit 
                    imageData={imageData} 
                    parentEntity={this.props.parentEntity}
                    handleSave={this.handleSaveImage}
                    handleCancel={this.handleCancel}
                />
            </div>
        );
    }

    getViewClasses(viewType, selectedViewType) {
      let classes = "view-link " + viewType;
      if(selectedViewType === viewType) {
        classes += " active";
      }
      return classes;
    }

    getImagesButtons() {
      if(this.props.merchant.uploadsAllowed) {
        return(
          <React.Fragment>
            <button className="btn btn-success m-2 float-right" onClick={this.handleNewClick}>New Image</button>
            <button className="btn btn-danger m-2 float-right" onClick={this.handleDeleteImages}>Delete Images</button>
          </React.Fragment>
        );
      } else {
        return(
          <div key="div-warning" className="alert alert-warning" role="alert">
            <div>File uploads are currently disabled for your business. Once file uploads are enabled, buttons will be displayed in this location, providing the ability to add and delete images.</div>
          </div>
        );
      }
    }

    getImagesTable(images, selectedImages, sortColumn) {
        if(!images) {
            return ('');
        }
        return(<EntityImagesTable 
          images={images}
          sortColumn={sortColumn}
          onDelete={this.handleDelete}
          onSort={this.handleSortByColumn} 
          onSelect={this.handleSelectClick}
          onClickEdit={this.handleEditClick}
          selectedImages={selectedImages}
          />
        );
    }

    render() { 
        return (
            <div>
                { this.renderImages() }
            </div>
        );
    }

}
 
export default EntityImages;